<template>
  <div class="loading"  >
    <canvas
      ref="canvas"
      class="loading-canvas"
      width="375"
      height="100"
    ></canvas><div :style="{ color: options.textColor, font: '16px Consolas' }">
      {{ options.text }}
    </div>
  </div>
  <div v-if="options.modal" class="loading-toast"></div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import useProgress from "./progress";
export default defineComponent({
  props: {
    options: Object,
  },
  setup(props, ctx) {
    const { canvas } = useProgress(props.options);
    return {
      canvas,
    };
  },
});
</script>

<style lang="scss" scoped>
.loading{
    position: fixed;
   z-index: 101;
  top: 50%;
  left:  50%;
  transform: translate(-50%,-50%);
  text-align: center;
  line-height: 0;
  // background-color: #fff;
  div{
    transform: translateY(-100%);
  }
}
.modal{
   position: fixed;
   z-index: 1001;
}
.loading-canvas {
  transform: scale(0.5, 0.5);
  display: block;
  margin: 0 auto;
}
.loading-toast{
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(255,255,255,0.55);
}
</style>
