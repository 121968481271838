
import { defineComponent } from "vue";
import useProgress from "./progress";
export default defineComponent({
  props: {
    options: Object,
  },
  setup(props, ctx) {
    const { canvas } = useProgress(props.options);
    return {
      canvas,
    };
  },
});
