import { ref, onMounted } from "vue";
import DrawLoading , { LoadingOption }  from './useArc'
const useProgress = (options: LoadingOption = {}) => {
    const canvas = ref<HTMLCanvasElement | null>(null);
    onMounted(() => {
        const context = (canvas.value as HTMLCanvasElement).getContext("2d");
        const drawLoading = new DrawLoading(context as CanvasRenderingContext2D , options )
        drawLoading.init()
    });
    return {
        canvas
    }
}




export default useProgress